@import "~bootstrap/scss/bootstrap.scss";
@import "./styles/variables.scss";
@import "./styles/shared.scss";
@import "./styles/catalogue-details.scss";
@import "./styles/contact-us.scss";
@import "./styles/header.scss";
@import "./styles/banner.scss";
@import "./styles/brands.scss";
@import "./styles/map.scss";
@import "./styles/search.scss";
@import "./styles/footer.scss";
@import "./styles/catalogues.scss";
