.footer {
  background-color: $blue;
  padding: 30px 0 30px 0;
}

.copyright {
  background-color: $blue;
  color: $white;
  opacity: 0.75;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}
