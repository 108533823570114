
.slider {
  .each-fade {
    display: flex;
    flex-direction: column;
    .image-container {
      width: 100%;
      height: 70vh;
      img {
        object-fit: cover;
        height: 70vh;
        width: 100%;
        object-position: center bottom;
      }
      .overlay {
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        height: 70vh;
        width: 100%;
        text-align: center;
        opacity: 1;
      }
    }
    .image-caption {
      margin: auto;
      position: absolute;
      left: 0; 
      bottom: 0; 
      right: 0;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: center;
      border-radius: 4px;
    }
  }
  .react-slideshow-container .nav:last-of-type {
    position: absolute;
    top: 32%;
    z-index: 100;
    left: 98%;
  }
  .react-slideshow-container .nav:first-of-type {
    position: absolute;
    top: 32%;
    z-index: 100;
    left: 2%;
  }
}

@media only screen and (max-width: 767px) {
  .slider {
    .each-fade { 
      .image-caption {
        width: 100%;
      }
    }
  }
}
