.horizontal-banner {
  padding-top: 10rem;
  img {
    width: 100%;
    height: 460px;
    object-fit: cover;
    object-position: center center;
  }
  .banner-caption {
    background-color: $blue;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text-content {
      padding: 0 25px 0 25px;
    }
  }
}

.catalogues {
  padding: 30px 0 50px 0;
  .catalogue-card {
    border-radius: 5px;
    background-color: $white;
    border: none;
    margin-bottom: 35px;
    .card-img-top {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      width: 100%;
      height: 240px;
      object-fit: cover;
      object-position: center top;
    }
    .arrow-img {
      height: 15px;
      width: 15px;
      object-fit: contain;
    }
  }
  &.custom-padding {
    padding: 10rem 0 20rem 0;
    .box-height {
      min-height: 400px;
    }
  }
}