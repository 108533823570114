@import "../styles/variables.scss";

.contact-us {
  padding-bottom: 4rem;
  .title {
    background: url(/assets/images/contact-us.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 50vh;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .box {
    margin-top: -7rem;
    .contact-box {
      background-color: $white;
      border-radius: 5px;
      padding: 30px 40px 30px 40px;
    }
    .info-box {
      background-color: $orange;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 30px 40px 30px 40px;
      margin-top: 6rem;
      hr {
        border-top: 1px solid $white;
      }
    }
    form {
      label {
        color: $black;
        font-size: 14px;
      }
      .form-control {
        border-radius: 8px;
        border: solid 1px #d3d2d2;
        background-color: #fcfcfc;
        font-size: 13px;
      }
      input.form-control {
        height: 44px;
      }
      input::placeholder,
      textarea::placeholder {
        color: #c5c0c0;
      }
    }
  }
}

.Toastify__toast {
  border-radius: 4px;
  font-family: "Poppins";
}

@media only screen and (max-width: 767px) {
  .contact-us {
    .box {
      margin-top: -15rem;
      .contact-box {
        padding: 15px 20px 15px 20px;
      }
      .info-box {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
        padding: 15px 20px 15px 20px;
      }
    }
  }
}
