.header {
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 0;
  right: 0;
  width: 100%;
  .nav-icons {
    display: inline-flex;
    align-items: center;
    li {
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .light-hr {
    border-top: 1px solid rgba(255, 255, 255, 0.8)
  }
  .dark-hr {
    border-top: 1px solid rgba(0, 0, 0, 0.8)
  }
  .navbar {
    padding: 0px;
  }
}