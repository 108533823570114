.search {
  min-height: 10vh;
  padding: 1rem 0;
  background-color: $blue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  form {
    width: 55%;
    margin: 0 auto;
    .form-control {
      border-radius: 0px;      
      border: none;
      background-color: $white;
    }
  }
}

@media only screen and (max-width: 767px) {
  .search {
    form {
      width: 100%;
      input {
        margin-bottom: 15px;
      }
    }
  }
}