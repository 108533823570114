// Fonts
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cairo&display=swap");

// Colors
$white: #ffffff;
$black: #2b2b2b;
$blue: #6e7d8e;
$orange: #e8a98a;
$gray: #939393;
$silver: #dfdfdf;
$light-gray: #adadad;
