.brands {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 1.6)
    ),
    url("/assets/images/our-brand.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 50px 0 50px 0;
  .subtitle {
    font-size: 17px;
  }
  .brand-card {
    cursor: pointer;
    position: relative;
    .card-img {
      width: 100%;
      height: 240px;
      object-fit: cover;
      border-radius: 5px;
    }
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 50px;
      height: 50px;
      overflow: hidden;
      top: 0;
      right: 0;
      background-color: $orange;
      border-radius: 0 4px 0 32px;
    }
    .go-arrow {
      color: $white;
      margin-top: -4px;
      margin-right: -4px;
    }
  }  
}

@media only screen and (max-width: 767px) {
  .brands {
    .brand-card { 
      margin-bottom: 30px;
    }
  }
}