
body {
  font-family: "Cairo", sans-serif;
}
.ar-text {
  font-family: "Cairo", sans-serif !important;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.clickable {
  cursor: pointer;
}

.orange-bg {
  background-color: $orange;
}

.blue-bg {
  background-color: $blue;
}

.dark-text {
  color: $black;
}

.gray-text {
  color: $gray;
}

.orange-btn {
  background-color: $orange;
  color: $white;
  border: 1px solid $orange;

  &:hover {
    background-color: $white;
    color: $orange;
    transition: background-color ease-out 0.3s;
  }
}

.black-btn {
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  line-height: 1.7;
  &:hover {
    background-color: $white;
    color: $black;
    transition: background-color ease-out 0.3s;
  }
}

.btn-link {
  color: $blue;
  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.light-gray {
  color: $light-gray;
}

.blue-text {
  color: $blue;
}
